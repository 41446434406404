import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/admin',
      component: () => import('../views/admin/main.vue'),
      children: [
        {
          path: 'index',
          name: 'adminIndexComponent',
          component: () => import('../views/admin/index.vue')
        },
        {
          path: 'categories',
          name: 'adminCategoriesComponent',
          component: () => import('../views/admin/categories.vue')
        },
        {
          path: 'carousel',
          name: 'adminCarouselComponent',
          component: () => import('../views/admin/carousel.vue')
        },
        {
          path: 'user',
          name: 'adminUserComponent',
          component: () => import('../views/admin/user.vue')
        },
        {
          path: 'userLog',
          name: 'adminUserLogComponent',
          component: () => import('../views/admin/userLog.vue')
        }
      ]
    },
    {
      path: '/home',
      name: 'HomeComponent',
      component: () => import('../views/home.vue')
    },
    {
      path: '/article',
      name: 'articleComponent',
      component: () => import('@/views/article.vue')
    },
    {
      path: '/createArticle',
      name: 'createArticleComponent',
      component: () => import('@/views/createArticle.vue')
    },
    {
      path: '/articleDetail',
      name: 'articleDetailComponent',
      component: () => import('@/views/articleDetail.vue')
    },
    {
      path: '/login',
      name: 'loginComponent',
      component: () => import('@/views/login.vue')
    },
    {
      path: '/register',
      name: 'registerComponent',
      component: () => import('@/views/register.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  // 自定义路由拦截逻辑
  next()
})

export default router